import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCart } from './CartContext'; // Import the custom hook
import './ProductDetails.css';

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [activeTab, setActiveTab] = useState('info');
  const { addToCart } = useCart(); // Use the cart context
  // Use the environment variable for Azure Blob Storage URL, or fallback to hardcoded URL
  const blobUrl = process.env.REACT_APP_BLOB_URL || "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/";


  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`https://shivprathishthanfatakamart.in/products/details/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProduct(data.product);
        setRelatedProducts(data.relatedProducts);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  if (!product) return <p>Loading...</p>;

  const handleAddToCart = () => {
    addToCart(product);
  };

  return (
    <div className="product-details mt-5">
      <div className="product-info">
        <div className="product-image-container1">
          <img  src={`${blobUrl}${product.image_url}`} alt={product.name} className="product-image" />
        </div>
        <div className="product-details-right">
          <h1 className="product-name">{product.name}</h1>
          <p className="product-price discount-value">Original Price: <span className="price-value discount-value">INR {product.price}</span></p>
          <p className="product-discount-price discount-value">Discounted Price: <span className="discount-value">INR {product.discount_price}</span></p>

          {/* Add to Cart and Continue Shopping Buttons */}
          <div className="cart-actions">
           
            <button className="btn btn-secondary demo90" onClick={() => navigate('/shop')}>
              Continue Shopping
            </button>
          </div>

           <br></br>
           <br></br>
          <div className="product-tabs">
            <button onClick={() => setActiveTab('info')} className={activeTab === 'info' ? 'active' : ''}>
              Specifications
            </button>
            <button onClick={() => setActiveTab('description')} className={activeTab === 'description' ? 'active' : ''}>
              Description
            </button>
            {product.product_video && (
              <button onClick={() => setActiveTab('video')} className={activeTab === 'video' ? 'active' : ''}>
                Video
              </button>
            )}
          </div>
          <div className="tab-content">
            {activeTab === 'info' && (
              <div className="info-content">
                <h2>Product Specifications</h2>
                <p><strong>Product Code:</strong> {product.product_code}</p>
                <p><strong>Rate Per:</strong> {product.rate_per}</p>
                <p><strong>Contents Per:</strong> {product.contents_per}</p>
              </div>
            )}
            {activeTab === 'description' && (
              <div className="description-content">
                <h2>Description</h2>
                <p>{product.description}</p>
              </div>
            )}
           {activeTab === 'video' && (
  <div className="video-content">
    <h2>Product Video</h2>
    <iframe
      src={product.product_video.includes("watch?v=") 
           ? product.product_video.replace("watch?v=", "embed/") 
           : product.product_video}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="Product Video"
      className="video-frame"
    />
  </div>
)}

          </div>
        </div>
      </div>

      <h2>Related Products</h2>
      <div className="related-products">
  {relatedProducts.map((relatedProduct) => (
    <div className="related-product-item" key={relatedProduct.id}>
      <img src={`${blobUrl}${relatedProduct.image_url}`} alt={relatedProduct.name} />
      <h3>{relatedProduct.name}</h3>
      <p>INR {relatedProduct.price}</p>
    </div>
  ))}
</div>

    </div>
  );
};

export default ProductDetails;
