import React from 'react';
import './ContactBackground.css';

const ContactBackground = () => {
  return (
    <section className="breadcrumb-section section-b-space">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Touch with us</h3>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.php">
                    <i className="fas fa-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Contact</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBackground;
