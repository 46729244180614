import React from 'react';
import './BannerSection.css'; // Import custom styles if needed

const BannerSection = () => {
  return (
    <section className="banner-section">
      <div className="container-fluid">
        <div className="row g-3">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="banner-image">
              <a href="shop.php">
                <img
                   src="images/kids.png"
                  className="w-100 blur-up lazyload"
                  alt="Anil Fireworks"
                />
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="banner-image">
              <a href="shop.php#cat_11">
                <img
                 src="images/gift.png"
                  className="w-100 blur-up lazyload"
                  alt="Anil Crackers"
                />
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="banner-image">
              <a href="shop.php#cat_24">
                <img
                   src="images/new.png"
                  className="w-100 blur-up lazyload"
                  alt="Buy Crackers Online"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
