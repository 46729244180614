import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './AddCategory.css'; // Import the CSS file

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [message, setMessage] = useState(''); // To display success or error messages

  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset message state
    setMessage('');

    try {
      // Make an API call to add the category
      const response = await fetch('https://shivprathishthanfatakamart.in/add-category', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: categoryName }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(result.message); // Display success message
        setCategoryName(''); // Clear the input field
      } else {
        setMessage(result.message || 'Failed to add category'); // Display error message
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error adding category'); // Display error message
    }
  };

  const handleBackClick = () => {
    navigate('/admin-dashboard'); // Redirect to the admin dashboard route
  };

  return (
    <div className="add-category-container">
      <div className="form-wrapper">
        <h2 className="form-title">Add New Category</h2>
        <form onSubmit={handleSubmit} className="category-form">
          <div className="input-group">
            <input
              type="text"
              className="form-input"
              placeholder="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
            />
            <label className="form-label"></label>
          </div>
          <button type="submit" className="submit-btn">Add Category</button>
        </form>
        {/* Message display */}
        {message && <p className="message">{message}</p>}

        {/* Back to Admin Dashboard Button */}
        <button onClick={handleBackClick} className="back-btn">
          Back to Admin Dashboard
        </button>
      </div>
    </div>
  );
};

export default AddCategory;
