import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children }) => {
    const { isAdminLoggedIn } = useAuth();

    // Only allow access if the admin is logged in
    return isAdminLoggedIn ? children : <Navigate to="/" />;
};

export default PrivateRoute;
