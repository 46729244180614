import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';  // Import the CSS file for styling

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match!');
      return;
    }
    
    try {
      const response = await axios.put('https://shivprathishthanfatakamart.in/admin/change-password', {
        newPassword,
      });

      if (response.status === 200) {
        setMessage('Password changed successfully. Logging out...');
        localStorage.removeItem('adminToken');
        setTimeout(() => {
          navigate('/');
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className="change-password-container">
      <div className="change-password-form-wrapper">
        <h2 className="change-password-heading gallery-heading">Change Password</h2>
        <form onSubmit={handleChangePassword} className="change-password-form">
          <div className="change-password-form-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="change-password-input"
            />
          </div>
          <div className="change-password-form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="change-password-input"
            />
          </div>
          <button type="submit" className="change-password-button">Change Password</button>
        </form>
        {message && <p className="change-password-message">{message}</p>}
      </div>
    </div>
  );
};

export default ChangePassword;
