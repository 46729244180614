import React from 'react';
import './Contact.css'; // Include your custom CSS for updated styles
import ContactBackground from './ContactBackground';

const Contact = () => {
  return (
    <>
      <ContactBackground />

      {/* Contact Section Start */}
      <section className="contact-section">
        <div className="container">
          <div className="row g-4">
            {/* Contact Form */}
            <div className="col-lg-7">
              <div className="contact-form-container">
                <h2 className="section-title gallery-heading">Get in Touch</h2>
                <p>We would love to hear from you! Fill out the form below.</p>
                
                <form>
                  <div className="row g-3 mt-3">
                    <div className="col-md-6">
                      <label htmlFor="first" className="form-label">First Name</label>
                      <input type="text" className="form-control" id="first" placeholder="First Name" required />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="last" className="form-label">Last Name</label>
                      <input type="text" className="form-control" id="last" placeholder="Last Name" required />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" className="form-control" id="email" placeholder="Email Address" required />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email2" className="form-label">Mobile Number</label>
                      <input type="number" className="form-control" id="email2" placeholder="Mobile Number" required />
                    </div>
                    <div className="col-12">
                      <label htmlFor="comment" className="form-label">Your Message</label>
                      <textarea className="form-control" id="comment" rows="4" placeholder="Write your message here..." required></textarea>
                    </div>
                    <div className="col-12 text-center">
                      <button type="submit" className="btn  but">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* Contact Information */}
            <div className="col-lg-5">
              <div className="contact-info">
                <h2 className='gallery-heading'>Contact Details</h2>
                <p>Feel free to reach out to us through the following methods:</p>

                <div className="info-box">
                  <i className="fa fa-map-pin"></i>
                  <div>
                    <h4>Address:</h4>
                    <p>
                    Shivprathisthan Fataka Mart,<br />
                    Shivprathisthan Fataka Mart,Sr No 58 Gokul Nagar Shivpratishthan Chowk Kondhwa Bk,<br />
                      Pune, Maharashtra 411048
                    </p>
                  </div>
                </div>

                <div className="info-box">
                  <i className="fa fa-phone"></i>
                  <div>
                    <h4>Phone:</h4>
                    <p>
                      Mobile: +91 98813 03879<br />
                      Office: +91 73851 03879
                    </p>
                  </div>
                </div>

                <div className="info-box">
                  <i className="fa fa-envelope"></i>
                  <div>
                    <h4>Email:</h4>
                    <p>sushant9881303879@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section End */}

      {/* Map Section Start */}
      <section className="map-section container-fluid">
        <div className="location-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15138.68062123104!2d73.8738999!3d18.4532795!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb33d4c08741%3A0x3b22c118f7cd924d!2sShivprathisthan%20Fataka%20Mart!5e0!3m2!1sen!2sin!4v1725798753492!5m2!1sen!2sin"
            style={{ border: 0, width: '100%', height: '400px' }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      {/* Map Section End */}
    </>
  );
};

export default Contact;
