import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './EditProduct.css';

const EditProduct = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [productToEdit, setProductToEdit] = useState(null);
  const [editing, setEditing] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const blobUrl = process.env.REACT_APP_BLOB_URL || "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/";

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://shivprathishthanfatakamart.in/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setErrorMessage('Failed to load categories. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const fetchProducts = async () => {
    if (selectedCategoryId) {
      setLoading(true);
      try {
        const response = await axios.get(`https://shivprathishthanfatakamart.in/products-by-category/${selectedCategoryId}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setErrorMessage('Failed to load products. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [selectedCategoryId]);

  useEffect(() => {
    const searchProducts = async () => {
      setLoading(true);
      try {
        if (searchQuery.length > 0) {
          const response = await axios.get('https://shivprathishthanfatakamart.in/search-products', { params: { searchQuery } });
          setProducts(response.data);
        } else {
          await fetchProducts();
        }
      } catch (error) {
        console.error('Error searching products:', error);
        setErrorMessage('Failed to search products. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    searchProducts();
  }, [searchQuery, selectedCategoryId]);

  const handleEdit = (product) => {
    setProductToEdit({ ...product, details: product.details || '' });
    setEditing(true);
    setSelectedImage(null);

    // Smooth scroll to edit form
    setTimeout(() => {
      const editForm = document.querySelector('.edit-form');
      if (editForm) {
        editForm.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleUpdateProduct = async (event) => {
    event.preventDefault();
    const updatedProductData = { ...productToEdit };
  
    if (selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
  
      try {
        const uploadResponse = await axios.put(`https://shivprathishthanfatakamart.in/upload-image/${productToEdit.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        
        // Extract the image name from the uploaded URL
        const imageUrl = uploadResponse.data.image_url;
        const imageName = imageUrl.split('/').pop(); // This will get the image name from the URL
        updatedProductData.image_url = imageName;
  
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
        return;
      }
    }
  
    try {
      await axios.put(`https://shivprathishthanfatakamart.in/products/${productToEdit.id}`, updatedProductData);
      alert('Product updated successfully!');
      setEditing(false);
      setProductToEdit(null);
      setSelectedImage(null);
      await fetchProducts();
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update the product. Please try again.');
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`https://shivprathishthanfatakamart.in/products/${productId}`);
        setDeleteMessage('Product deleted successfully!');
        setTimeout(() => setDeleteMessage(''), 3000);
        await fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('Failed to delete the product. Please try again.');
      }
    }
  };

  const handleBackToDashboard = () => {
    navigate('/admin-dashboard');
  };

  return (
    <div className="edit-product-container mt-5 mb-5">
      <button onClick={handleBackToDashboard} className='back-button'>Back to Admin Dashboard</button>
      <h1 className='gallery-heading'>Edit Products</h1>

      {loading && <p>Loading...</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <div className="category-selection">
        <label htmlFor="category">Select Category:</label>
        <select
          id="category"
          value={selectedCategoryId}
          onChange={(e) => setSelectedCategoryId(e.target.value)}
          className="category-select small-select"
        >
          <option value="">--Select a category--</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
      </div>

      <div className="search">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input small-input"
        />
      </div>

      {deleteMessage && <p className="delete-message">{deleteMessage}</p>}
      <div className="product-list">
        {products.length === 0 ? (
          <p>No products found.</p>
        ) : (
          products.map(product => (
            <div key={product.id} className="product-item">
              {product.image_url ? (
                <img className='demo98' src={`${blobUrl}${product.image_url}`} alt={product.name} />
              ) : (
                <div className="no-image">No Image Available</div>
              )}
              <div className="product-info">
                <h2>{product.name}</h2>
                <p><span className="bold-text">Discount Price:</span> INR {product.discount_price}</p>
                <p style={{ margin: '10px 0' }}><span className="bold-text">Original Price:</span> INR {product.price}</p>
                <div className="button-group">
                  <button onClick={() => handleEdit(product)}>Edit</button>
                  <button onClick={() => handleDelete(product.id)}>Delete</button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {editing && productToEdit && (
        <div className="edit-form">
          <h2>Edit Product</h2>
          <form onSubmit={handleUpdateProduct}>
            <div className="form-group">
              <label htmlFor="name">Product Name:</label>
              <input
                type="text"
                id="name"
                value={productToEdit.name}
                onChange={(e) => setProductToEdit({ ...productToEdit, name: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="discount_price">Discount Price:</label>
              <input
                type="number"
                id="discount_price"
                value={productToEdit.discount_price}
                onChange={(e) => setProductToEdit({ ...productToEdit, discount_price: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="price">Original Price:</label>
              <input
                type="number"
                id="price"
                value={productToEdit.price}
                onChange={(e) => setProductToEdit({ ...productToEdit, price: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="image">Upload Image:</label>
              <input
                type="file"
                id="image"
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
            </div>

            <div className="form-group">
              <label htmlFor="product_video">Product Video:</label>
              <input type="text" id="product_video" value={productToEdit.product_video} onChange={(e) => setProductToEdit({ ...productToEdit, product_video: e.target.value })} />
            </div>
            <div className="form-group">
              <label htmlFor="contents_per">Contents Per:</label>
              <input type="text" id="contents_per" value={productToEdit.contents_per} onChange={(e) => setProductToEdit({ ...productToEdit, contents_per: e.target.value })} />
            </div>

            <div className="form-group">
              <label htmlFor="product_code">Product Code:</label>
              <input type="text" id="product_code" value={productToEdit.product_code} onChange={(e) => setProductToEdit({ ...productToEdit, product_code: e.target.value })} required />
            </div>
            <div className="form-group">
              <label htmlFor="rate_per">Rate Per:</label>
              <input type="text" id="rate_per" value={productToEdit.rate_per} onChange={(e) => setProductToEdit({ ...productToEdit, rate_per: e.target.value })} />
            </div>


            <div className="form-group">
              <label htmlFor="description">Description:</label>
              <textarea id="description" value={productToEdit.description} onChange={(e) => setProductToEdit({ ...productToEdit, description: e.target.value })} />
            </div>
            <button type="submit">Update Product</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditProduct;
