import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AutoSlidingProductCarousel.css";
import { useCart } from './CartContext'; // Assuming you have this context
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'; // Import icons
// Use the environment variable for Azure Blob Storage URL, or fallback to hardcoded URL
const blobUrl = process.env.REACT_APP_BLOB_URL || "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/";




const AutoSlidingProductCarousel = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const { addToCart, cart, removeFromCart } = useCart(); // Using the cart context

  useEffect(() => {
    fetch("https://shivprathishthanfatakamart.in/products-by-category/3") // Adjust the endpoint as necessary
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setProducts(data))
      .catch((error) => {
        console.error("Error fetching products:", error);
        setError("Failed to load products.");
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true, // Enable center mode
    centerPadding: '40px', // Adjust padding on the sides
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '20px', // Less padding for medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '10px', // Even less padding for small screens
        },
      },
    ],
  };
  
  

  const handleQuantityChange = (productId, operation) => {
    const currentQuantity = cart.find(item => item.id === productId)?.quantity || 0;

    if (operation === 'increment') {
      // Find the product in the products array
      const product = products.find(p => p.id === productId);
      if (product) {
        addToCart(product);
      }
    } else if (operation === 'decrement' && currentQuantity > 0) {
      removeFromCart(productId);
    }
  };

  return (
    <div className="container">
      <h2 className="gallery-heading">Sparklers</h2>
      {error && <p>{error}</p>}
      <div className="auto-sliding-product-carousel container-fluid">
        <Slider {...settings}>
          {products.length > 0 ? (
            products.map((product) => {
              const currentQuantity = cart.find(item => item.id === product.id)?.quantity || 0;
              return (
                <div key={product.id} className="auto-sliding-product-item">
                  <div className="auto-sliding-product-image-container">
                    <img
                      src={`${blobUrl}${product.image_url}`}
                      alt={product.name}
                      className="auto-sliding-product-image"
                      onError={(e) => {
                        e.target.src = "/images/default-image.png"; // Fallback image
                      }}
                    />
                  
                  
                  </div>
                  <div className="auto-sliding-product-info">
                    <h3 className="auto-sliding-product-name">{product.name}</h3>
                    <p className="auto-sliding-product-price">
  {product.discount_price ? (
    <>
      {/* Original price without strikethrough */}
      <span className="auto-sliding-original-price">
      INR {product.discount_price.toFixed(2)}
      </span>
      {/* Discount price with strikethrough */}
      <span className="auto-sliding-discount">
        <del> INR {product.price.toFixed(2)} </del>
        
      </span>
    </>
  ) : (
    <span>INR {product.price.toFixed(2)}</span>
  )}
</p>



                    {/* Add-to-cart functionality with + and - buttons */}
                    {currentQuantity > 0 ? (
                      <div className="quantity-selection">
                        <button
                          className="quantity-button"
                          onClick={() => handleQuantityChange(product.id, 'decrement')}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <input
                          type="number"
                          value={currentQuantity}
                          readOnly
                          className="quantity-input"
                        />
                        <button
                          className="quantity-button"
                          onClick={() => handleQuantityChange(product.id, 'increment')}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    ) : (
                      <button
                        className="auto-sliding-add-to-cart"
                        onClick={() => handleQuantityChange(product.id, 'increment')}
                      >
                        Add To Cart
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p>No products available</p>
          )}
        </Slider>
      </div>
    </div>
  );
};

export default AutoSlidingProductCarousel;
