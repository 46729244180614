import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Payment.css';

const Payment = () => {
  const { state } = useLocation();
  const { totalPrice, productList } = state || {};

  const [billingInfo, setBillingInfo] = useState({
    name: '',
    address: '',
    mobile: '',
    email: '',
    city: '',
    state: ''
  });

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo({ ...billingInfo, [name]: value });
  };

  const isValidMobile = (mobile) => {
    const mobilePattern = /^[6-9]\d{9}$/;
    return mobilePattern.test(mobile);
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleProceedToPayment = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message

    if (!termsAccepted) {
      setErrorMessage('Please accept the terms and conditions');
      return;
    }

    if (!isValidMobile(billingInfo.mobile)) {
      setErrorMessage('Please enter a valid 10-digit mobile number');
      return;
    }

    if (!isValidEmail(billingInfo.email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    if (discountedPrice < 1500) {
      setErrorMessage('Minimum purchase of Rs.1500 is required to proceed.');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('https://shivprathishthanfatakamart.in/api/generate-pdf', {
        ...billingInfo,
        totalPrice: totalPrice || 100,
        productList,
        prices: productList.map(product => product.discount_price)
      });

      if (response.status === 200) {
        alert('Your order has been successfully placed. We have sent the invoice via WhatsApp, and our team will get back to you shortly.');
        setBillingInfo({ name: '', address: '', mobile: '', email: '', city: '', state: '' });
        setTermsAccepted(false);
      }
    } catch (error) {
      console.error('Error during payment processing:', error);
      setErrorMessage('Failed to process payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const originalPrice = productList.reduce((total, product) => total + (product.price * product.quantity), 0);
  const discountedPrice = productList.reduce((total, product) => total + (product.discount_price * product.quantity), 0);
  const savingsAmount = originalPrice - discountedPrice;

  return (
    <div className="payment-container mt-5 mb-5">
      <h1 className="gallery-heading">Billing Address</h1>
      <form className="billing-form" onSubmit={handleProceedToPayment}>
        <div className="form-group">
          <label htmlFor="name">Name <span className="required">*</span></label>
          <input type="text" name="name" id="name" value={billingInfo.name} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address <span className="required">*</span></label>
          <input type="text" name="address" id="address" value={billingInfo.address} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile <span className="required">*</span></label>
          <input type="text" name="mobile" id="mobile" value={billingInfo.mobile} onChange={handleInputChange} required maxLength={10} />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email <span className="required">*</span></label>
          <input type="email" name="email" id="email" value={billingInfo.email} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="city">City <span className="required">*</span></label>
          <input type="text" name="city" id="city" value={billingInfo.city} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="state">State <span className="required">*</span></label>
          <input type="text" name="state" id="state" value={billingInfo.state} onChange={handleInputChange} required />
        </div>
        <div className="checkbox-group">
          <input 
            type="checkbox" 
            checked={termsAccepted} 
            onChange={() => setTermsAccepted(!termsAccepted)} 
            required 
          />
          <span>I have read and agree to the website terms and conditions.</span>
        </div>

        <div className="price-details">
          <span>Original Price: <strong>Rs. {originalPrice.toFixed(2)}</strong></span>
          <span>Discounted Price: <strong>Rs. {discountedPrice.toFixed(2)}</strong></span>
          <span>Amount Saved: <strong>Rs. {savingsAmount.toFixed(2)}</strong></span>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button type="submit" className="custom-payment-btn" disabled={loading}>
          {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Proceed to Payment'}
        </button>
      </form>
    </div>
  );
};

export default Payment;
