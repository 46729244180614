import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './EditCategory.css'; // Import the CSS file

const EditCategory = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch categories when component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://shivprathishthanfatakamart.in/categories');
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleEditChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleCategorySelect = (e) => {
    const id = e.target.value;
    const selectedCategory = categories.find(cat => cat.id === parseInt(id));
    setSelectedCategory(id);
    setCategoryName(selectedCategory ? selectedCategory.name : '');
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await fetch(`https://shivprathishthanfatakamart.in/update-category/${selectedCategory}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: categoryName }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(result.message);
        setCategories(categories.map(cat => cat.id === parseInt(selectedCategory) ? result.category : cat));
        setSelectedCategory('');
        setCategoryName('');
      } else {
        setMessage(result.message || 'Failed to update category');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error updating category');
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;

    try {
      const response = await fetch(`https://shivprathishthanfatakamart.in/delete-category/${id}`, {
        method: 'DELETE',
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(result.message);
        setCategories(categories.filter(cat => cat.id !== parseInt(id)));
        setSelectedCategory('');
        setCategoryName('');
      } else {
        setMessage(result.message || 'Failed to delete category');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error deleting category');
    }
  };

  const handleBackToDashboard = () => {
    navigate('/admin-dashboard'); // Navigate to admin dashboard
  };

  return (
    <div className="edit-category-container">
      <div className="form-wrapper">
        <h2 className="form-title gallery-heading">Edit or Delete Category</h2>
        
        {/* Category Selection */}
        <div className="select-category">
          <select
            className="category-select"
            onChange={handleCategorySelect}
            value={selectedCategory}
          >
            <option value="">Select a category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
        </div>

        {/* Edit Form */}
        {selectedCategory && (
          <form onSubmit={handleUpdate} className="edit-form">
            <div className="input-group">
              <input
                type="text"
                className="form-input"
                placeholder="Category Name"
                value={categoryName}
                onChange={handleEditChange}
                required
              />
            </div>
            <button type="submit" className="update submit-btn">Update Category</button>
            <button
              type="button"
              className="delete-btn"
              onClick={() => handleDelete(selectedCategory)}
            >
              Delete Category
            </button>
          </form>
        )}

        {/* Message display */}
        {message && <p className="message">{message}</p>}

        {/* Back to Admin Dashboard Button */}
        <button className="btn btn-primary mt-3" onClick={handleBackToDashboard}>
          Back to Admin Dashboard
        </button>
      </div>
    </div>
  );
};

export default EditCategory;
