import React from 'react';
import './ImageParallax.css'; // CSS file for styling
import { Link } from 'react-router-dom';


const ImageParallax = () => {
  return (
    <div className="image-section">
      <div className="parallax">
        <div className="image-text">
          <p>At Shiv Pratishthan, we bring excitement to your celebrations with a spectacular range of high-quality firecrackers. Whether it's Diwali, New Year's Eve, or any other special occasion, we have the perfect products to light up your night.</p>
          <Link to="Contact" className="btn btn-outline-dark contact1">Contact Us</Link>
       
      
        </div>
      </div>
    </div>
  );
};

export default ImageParallax;
