import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-sm-space container-fluid">
      <div className="main-footer">
        <div className="container">
          <div className="row gy-4 align-items-start">
            {/* Brand Logo and Contact Information */}
            <div className="col-lg-6 col-md-12 d-flex align-items-start">
              <div className="brand-logo">
                <a href="/" className="footer-logo">
                  <img src="images/logo.jpeg" className="img-fluid blur-up lazyload" alt="logo" />
                </a>
              </div>
              <div className="footer-contact ms-4">
                <ul className="contact-lists">
                  <li>
                    <span className="font-light">
                      <b className='demo45'>Address:</b> Shivprathisthan Fataka Mart,Sr No 58 Gokul Nagar Shivpratishthan Chowk Kondhwa Bk,Pune Maharashtra 411048
                    </span>
                  </li>
                  <li>
                    <span className="font-light">
                      <b>Mobile:</b> +91 98813 03879 <br />
                      <b>Office:</b> +91 73851 03879
                    </span>
                  </li>
                  <li>
                    <span className="font-light">
                      <b>Email:</b> sushant9881303879@gmail.com
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Footer Links */}
            <div className="col-lg-2 col-md-4 col-sm-6 footer-section">
              <h3 className="footer-title">About Us</h3>
              <ul>
                <li><a href="/" className="font-dark">Home</a></li>
                <li><a href="/shop" className="font-dark">Buy Crackers</a></li>
                <li><a href="/about" className="font-dark">About Us</a></li>
                <li><a href="/contact" className="font-dark">Contact</a></li>
              </ul>
            </div>

            {/* Google Maps Section */}
            <div className="col-lg-2 col-md-4 col-sm-6 footer-section">
              <h3 className="footer-title">Find Us</h3>
              <a href="/contact" className="map-link">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15138.68062123104!2d73.8738999!3d18.4532795!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb33d4c08741%3A0x3b22c118f7cd924d!2sShivprathisthan%20Fataka%20Mart!5e0!3m2!1sen!2sin!4v1725798753492!5m2!1sen!2sin"
                  width="100%"
                  height="150"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Google Map"
                ></iframe>
              </a>
            </div>

            {/* Social Icons */}
            <div className="col-lg-2 col-md-4 col-sm-6 footer-section">
              <h3 className="footer-title">Follow Us</h3>
              <ul className="social-icons">
                <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/shivpratishtan_fataka_mart/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Sub Footer */}
      <div className="sub-footer">
        <div className="container">
          <div className="row gy-3">
            <div className="col-md-12 text-center">
              <p className="mb-0">© 2024, Shivprathisthan. Powered By <a href="https://malharinfotech.com/" target="_new" className="name1">Malhar Infotech</a></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
