import React, { useState, useEffect } from "react";
import Header from "./Header"; // Import the Header component
import "./ProductList.css";
import { useCart } from './CartContext'; // Import the CartContext

const ProductList = () => {
  const { cart, addToCart, removeFromCart } = useCart(); // Get cart functions from context
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Use the environment variable for Azure Blob Storage URL, or fallback to hardcoded URL
  const blobUrl = process.env.REACT_APP_BLOB_URL || "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/";

  // Fetch products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`https://shivprathishthanfatakamart.in/products-by-category/37`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Function to check if the product is in the cart and get the quantity
  const getProductQuantity = (productId) => {
    const item = cart.find((item) => item.id === productId);
    return item ? item.quantity : 0;
  };

  if (loading) {
    return <p>Loading products...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {/* Include the Header */}
      <Header />

      <h2 className="gallery-heading">New Arrival</h2>
      <div className="product-list">
        {products.length === 0 ? (
          <p>No products found</p>
        ) : (
          products.map((product) => {
            const quantity = getProductQuantity(product.id); // Get the quantity of the current product

            // Render original price and discount price
            const originalPrice = product.price ? product.price.toFixed(2) : null;
            const discountPrice = product.discount_price ? product.discount_price.toFixed(2) : null;

            return (
              <div className="product-item" key={product.id}>
                <div className="product-image-container">
                  <img
                    src={`${blobUrl}${product.image_url}`} // Construct the full image URL
                    alt={`Image of ${product.name}`}
                    className="product-image"
                  />
                </div>
                <div className="product-info">
                  <h3 className="product-name">{product.name}</h3>

                  {/* Display both original and discount prices if available */}
                  <p className="product-prices">
                    {originalPrice && (
                      <span className="discount-price">INR {discountPrice}</span>
                    )}
                    {discountPrice && (
                      <span className="original-price">INR {originalPrice} </span>
                    )}
                    <br />
                   
                  </p>

                  {quantity > 0 ? (
                    <div className="quantity-controls">
                      <button
                        className="quantity-button"
                        onClick={() => removeFromCart(product.id)}
                      >
                        -
                      </button>
                      <span className="product-quantity">{quantity}</span>
                      <button
                        className="quantity-button"
                        onClick={() => addToCart(product)}
                      >
                        +
                      </button>
                    </div>
                  ) : (
                    <button
                      className="add-to-cart"
                      onClick={() => addToCart(product)}
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ProductList;
