import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Components/Home';
import { AuthProvider, useAuth } from './Components/AuthContext'; 
import Contact from './Components/Contact';
import Header from './Components/Header';
import Footer from './Components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import About from './Components/About';
import ProductDetails from './Components/ProductDetails';
import Buy from './Components/Buy';
import AddCategory from './Components/AddCategory';
import EditCategory from './Components/EditCategory';
import AddProduct from './Components/AddProduct';
import EditProduct from './Components/EditProduct';
import ProductList from './Components/ProductList';
import AdminDashboard from './Components/AdminDashboard';
import PrivateRoute from './Components/PrivateRoute';
import Checkout from './Components/Checkout';
import Payment from './Components/Payment';
import ChangePassword from './Components/ChangePassword';
import { CartProvider } from './Components/CartContext';
import Login from './Components/Login';

const App = () => {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [cart, setCart] = useState([]);
  
  console.log('isAdminLoggedIn:', isAdminLoggedIn);
  console.log('setIsAdminLoggedIn:', typeof setIsAdminLoggedIn);


  // Handle cart update
  const handleCartUpdate = (updatedCart) => {
    setCart(updatedCart);
  };

  // Calculate total cart value
  
  // Add product to cart
  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };
  return (
    <AuthProvider>
    <CartProvider>
    <Router>
       
        <Header
          cart={cart}
          isAdminLoggedIn={isAdminLoggedIn} // Pass the login state
          setIsAdminLoggedIn={setIsAdminLoggedIn} // Pass the function to change the state
        /> 
        <Routes>
          <Route path="/" element={<Home />} />


          {/* Private routes for admin access */}


          <Route 
              path="/add-category" 
              element={
                <PrivateRoute>
                  <AddCategory />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/edit-category" 
              element={
                <PrivateRoute>
                  <EditCategory />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/add-product" 
              element={
                <PrivateRoute>
                  <AddProduct />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/edit-products" 
              element={
                <PrivateRoute>
                  <EditProduct />
                </PrivateRoute>
              } 
            />






          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Buy cart={cart} onCartUpdate={handleCartUpdate} />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/checkout" element={<Checkout cart={cart} onCartUpdate={handleCartUpdate} />} />
          
          <Route path="/products" element={<ProductList />} />
          
          <Route 
                    path="/login" 
                    element={<Login setIsAdminLoggedIn={setIsAdminLoggedIn} />} 
                />

                {/* Protected route for admin dashboard */}
                <Route path="/admin-dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />


                <Route 
                        path="/change-password" 
                        element={
                            <PrivateRoute>
                                <ChangePassword />
                            </PrivateRoute>
                        } 
                    />

          <Route path="/product/:id" element={<ProductDetails />} />
        </Routes>
        <Footer />
      
    </Router>
    </CartProvider>
    </AuthProvider>
  );
};
// Protected route component
const ProtectedRoute = ({ children }) => {
  const { isAdminLoggedIn } = useAuth();
  
  return isAdminLoggedIn ? children : <Navigate to="/" />;
};
export default App;
