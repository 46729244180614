import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AddProduct.css';

const AddProduct = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({
    name: '',
    price: '',
    discount_price: '',
    image_url: '',  // This will now store just the image name
    product_details: '',
    product_video: '',
    category_id: '',
    product_specification: '',
    product_code: '',
    rate_per: '',
    contents_per: '',
    description: ''
  });
  const [imageFile, setImageFile] = useState(null);

  const baseUrl = "https://shivprathishthanfatakamart.in"; // Base URL for the backend
  const uploadImageUrl = `${baseUrl}/api/upload`; // Image upload URL
  const blobStorageBaseUrl = "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/"; // Base URL for blob storage

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      let imageName = '';
      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);
  
        // Upload the image
        const uploadResponse = await axios.post(uploadImageUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
  
        // Get the image name from the response
        imageName = uploadResponse.data.imageName;
      }

      const updatedForm = { ...form, image_url: imageName }; // Only save the image name
  
      // Add product
      const response = await axios.post(`${baseUrl}/api/products`, updatedForm);
      alert('Product added successfully!');
  
      // Reset the form state
      setForm({
        name: '',
        price: '',
        discount_price: '',
        image_url: '',
        product_details: '',
        product_video: '',
        category_id: '',
        product_specification: '',
        product_code: '',
        rate_per: '',
        contents_per: '',
        description: ''
      });
      setImageFile(null);
    } catch (error) {
      console.error('Error adding product:', error.response?.data || error.message);
      alert('Error adding product');
    }
  };

  const handleBackToAdmin = () => {
    navigate('/admin-dashboard');
  };

  return (
    <div className="add-product mt-5 mb-5">
      <h2 className="gallery-heading">Add New Product</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Product Name"
          value={form.name}
          onChange={handleChange}
        />
        <input
          type="number"
          name="price"
          placeholder="Price"
          value={form.price}
          onChange={handleChange}
        />
        <input
          type="number"
          name="discount_price"
          placeholder="Discount Price"
          value={form.discount_price}
          onChange={handleChange}
        />
        <input
          type="file"
          onChange={handleImageChange}
          accept="image/*"
        />
        {form.image_url && (
          <img
            src={`${blobStorageBaseUrl}${form.image_url}`} // Construct full image URL
            alt="Uploaded Product"
           
          />
        )}
        <textarea
          name="product_details"
          placeholder="Product Details"
          value={form.product_details}
          onChange={handleChange}
        />
        <input
          type="text"
          name="product_video"
          placeholder="Product Video URL"
          value={form.product_video}
          onChange={handleChange}
        />
        <select
          name="category_id"
          value={form.category_id}
          onChange={handleChange}
        >
          <option value="">Select Category</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <textarea
          name="product_specification"
          placeholder="Product Specification"
          value={form.product_specification}
          onChange={handleChange}
        />
        <input
          type="text"
          name="product_code"
          placeholder="Product Code"
          value={form.product_code}
          onChange={handleChange}
        />
        <input
          type="text"
          name="rate_per"
          placeholder="Rate Per"
          value={form.rate_per}
          onChange={handleChange}
        />
        <input
          type="text"
          name="contents_per"
          placeholder="Contents Per"
          value={form.contents_per}
          onChange={handleChange}
        />
        <textarea
          name="description"
          placeholder="Description"
          value={form.description}
          onChange={handleChange}
        />
        <button type="submit">Add Product</button>
        <button type="button" onClick={handleBackToAdmin} className="back-to-admin">Back to Admin Dashboard</button>
      </form>
    </div>
  );
};

export default AddProduct;
