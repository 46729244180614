import React from 'react';
import SliderComponent from './SliderComponent';
import BannerSection from './BannerSection';
import ProductList from './ProductList';
import AutoSlidingProductCarousel from './AutoSlidingProductCarousel';
import ImageParallax from './ImageParallax';

const Home = () => {
 

  return (
    
        <>
       
        <SliderComponent></SliderComponent>
        <BannerSection></BannerSection>
        <br></br>
        <br></br>
        <ProductList/>
        <br></br>
        <br></br>   
        <AutoSlidingProductCarousel></AutoSlidingProductCarousel>  
        <br></br>
        <ImageParallax></ImageParallax>
        
        </>


  );
};

export default Home;
