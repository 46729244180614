import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import AboutBackground from './AboutBackground';

const About = () => {
  return (
    <>
    <AboutBackground></AboutBackground>
    <div className="about-section-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <img src="https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/slide4.jpeg" alt="About Us" className="img-fluid about-image" />
          </div>
          <div className="col-lg-6 col-md-6 text-center">
            <div className="inner-column">
              <h1>Welcome To <span>Shiv Pratishthan</span></h1>
              
              <p>
                At Shiv Pratishthan, we bring excitement to your celebrations with a spectacular range of high-quality firecrackers. Whether it's Diwali, New Year's Eve, or any other special occasion, we have the perfect products to light up your night. Our shop is dedicated to providing safe and vibrant fireworks that add an extra spark to your festivities.
              </p>
              
              <p>
                We prioritize safety and quality, ensuring that every firecracker you purchase is of the highest standard. Let Blaze Firecracker Emporium be your go-to destination for all things fireworks, where every purchase is sure to ignite joy and wonder in the sky.
              </p>
              
              <Link to="/contact" className="btn btn-lg btn-circle btn-outline-new-white">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
