import React from 'react';
import Slider from 'react-slick';
import './CategorySlider.css'; // External CSS

const sliderItems = [
  { id: 1, src: 'images/Single_Sound_Crackers.png', alt: 'Buy Crackers online', title: 'SINGLE SOUND CRACKERS' },
  { id: 2, src: 'images/Double-Sound-Crackers.png', alt: 'Buy Crackers online', title: 'MULTI SOUND CRACKERS' },
  { id: 3, src: 'images/Sparklers.png', alt: 'Buy Crackers online', title: 'SPARKLERS' },
  { id: 4, src: 'images/Chakkar.png', alt: 'Buy Crackers online', title: 'CHAKKARS' },
  { id: 5, src: 'images/Flowerpots.png', alt: 'Buy Crackers online', title: 'FLOWER POTS' },
  { id: 6, src: 'images/Twinkling-Star.png', alt: 'Buy Crackers online', title: 'TWINKLING STAR' },
  { id: 7, src: 'images/Pencils.png', alt: 'Buy Crackers online', title: 'PENCILS' },
  { id: 8, src: 'images/Rockets.png', alt: 'Buy Crackers online', title: 'ROCKETS' },
  { id: 9, src: 'images/Bomb.png', alt: 'Buy Crackers online', title: 'ATOM BOMB' },
  { id: 10, src: 'images/Loose-Crackers.png', alt: 'Buy Crackers online', title: 'LOOSE CRACKERS' },
  { id: 11, src: 'images/Gift-Boxes.png', alt: 'Buy Crackers online', title: 'GIFT BOXES' },
  { id: 12, src: 'images/Fancy-Ground-Function.png', alt: 'Buy Crackers online', title: 'FANCY ITEMS - GROUND FUNCTION' },
];

const CategorySlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768, // Medium devices (tablets)
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480, // Small devices (phones)
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <section className="category-slider">
      <div className="slider-container">
        <div className="slider-wrapper">
          <Slider {...settings}>
            {sliderItems.map(item => (
              <div key={item.id} className="slider-item">
                <div className="image-slider">
                  <div className="image-product">
                    <a href={`#cat_${item.id}`}>
                      <img src={item.src} className="img-fluid" alt={item.alt} />
                    </a>
                    <div className="image-contain">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default CategorySlider;
