import React from 'react';
import { useCart } from './CartContext'; // Import the custom hook

const CartTotal = () => {
  const { total } = useCart(); // Get the cart total

  return (
    <div>
      <i className="fa fa-shopping-cart pe-2"></i>
      <span> ₹ {total}</span>
    </div>
  );
};

export default CartTotal;
