import React from 'react';
import { useCart } from './CartContext';
import { useNavigate } from 'react-router-dom';
import './Checkout.css';

const Checkout = () => {
  const { cart, total, removeFromCart, addToCart } = useCart();
  const navigate = useNavigate();

  // Use the environment variable for Azure Blob Storage URL, or fallback to hardcoded URL
  const blobUrl = process.env.REACT_APP_BLOB_URL || "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/";

  const formatPrice = (price) => {
    return `INR ${price.toFixed(2)}`;
  };

  return (
    <div className="checkout-container">
      <h2 className='gallery-heading mt-2'>Checkout</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          <table className="checkout-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Product Name</th>
                <th>Price (Each)</th>
                <th>Quantity</th>
                <th>Total Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => {
                // Use discount_price if it exists
                const priceToDisplay = item.discount_price || item.price;

                return (
                  <tr key={item.id}>
                    <td>
                      <img
                        src={`${blobUrl}${item.image_url}`}
                        alt={item.name}
                        className="cart-item-image"
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>{formatPrice(priceToDisplay)}</td>
                    <td>
                      <button className="quantity-button" onClick={() => addToCart(item)}>+</button>
                      {item.quantity}
                      <button className="quantity-button" onClick={() => removeFromCart(item.id)}>-</button>
                    </td>
                    <td>{formatPrice(priceToDisplay * item.quantity)}</td>
                    <td>
                      <button className="remove-button" onClick={() => removeFromCart(item.id)}>Remove</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h3 className="total-price">Amount Payable: Rs. {formatPrice(total)}</h3>
          <button 
            className="checkout-button" 
            onClick={() => navigate('/payment', { 
              state: { 
                totalPrice: total, // Pass total price
                productList: cart.map(item => ({
                  name: item.name,
                  price: item.price, // Original price
                  discount_price: item.discount_price || item.price, // Pass discount price
                  quantity: item.quantity,
                }))
              } 
            })}>
            Proceed to Payment
          </button>
          <button 
            className="continue-shopping-button" 
            onClick={() => navigate('/shop')}>
            Continue Shopping
          </button>
        </>
      )}
    </div>
  );
};

export default Checkout;
