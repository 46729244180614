import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import CartTotal from './CartTotal';
import { useAuth } from './AuthContext'; // Import useAuth to access AuthContext

const Header = ({ totalPrice }) => {
    const { isAdminLoggedIn, setIsAdminLoggedIn } = useAuth(); // Use context for auth state
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const [loginCredentials, setLoginCredentials] = useState({ username: '', password: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [networkError, setNetworkError] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => setShowLoginPopup(true);
    const handleLogout = () => setShowLogoutPopup(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginCredentials((prevState) => ({ ...prevState, [name]: value }));
    };

    const submitLogin = async () => {
        const { username, password } = loginCredentials;

        try {
            const response = await fetch('https://shivprathishthanfatakamart.in/api/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

           

            if (response.ok) {
                const data = await response.json(); // Ensure to await and parse the response
               
                setIsAdminLoggedIn(true); // Update the login state
                setShowLoginPopup(false); // Hide login popup
                navigate('/admin-dashboard'); // Redirect to admin dashboard
            } else {
                const data = await response.json();
                setErrorMessage(data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setErrorMessage('An error occurred, please try again.');
        }
    };

    const confirmLogout = () => {
        setIsAdminLoggedIn(false); // Reset admin login state
        setShowLogoutPopup(false);
        alert('Logged Out Successfully!');
        navigate('/'); // Redirect to home after logout
    };

    const cancelLogout = () => setShowLogoutPopup(false);

    return (
        <header className="main-header container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src="images/logo.jpeg" className="img-fluid" alt="logo" style={{ maxWidth: '80px' }} />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item"><Link className="nav-link text-white" to="/">Home</Link></li>
                            {/* Conditionally render these links based on admin login status */}
                            {!isAdminLoggedIn && (
                                <>
                                    <li className="nav-item"><Link className="nav-link text-white" to="/shop">Buy Crackers</Link></li>
                                    <li className="nav-item"><Link className="nav-link text-white" to="/contact">Contact Us</Link></li>
                                    <li className="nav-item"><Link className="nav-link text-white" to="/about">About Us</Link></li>
                                </>
                            )}
                            {isAdminLoggedIn && (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link text-white" to="/admin-dashboard">Admin Dashboard</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link text-white" to="/change-password">Change Password</Link>
                                    </li>
                                </>
                            )}
                        </ul>

                        <div className="d-flex">
                            {isAdminLoggedIn ? (
                                <button type="button" className="btn btn-outline-light demo09" onClick={handleLogout}>Logout</button>
                            ) : (
                                <button type="button" className="btn btn-outline-light demo" onClick={handleLogin}>Admin Login</button>
                            )}
                        </div>
                    </div>

                    {!isAdminLoggedIn && (
                        <div className="cart-container ms-2">
                            <button
                                type="button"
                                className="btn btn-outline-light cart-button"
                                onClick={() => navigate('/checkout')}
                            >
                                <span>
                                    <span id="header_cart_total_amount">{totalPrice}</span>
                                </span>
                                <span className="cart-count">
                                    <CartTotal />
                                </span>
                            </button>
                        </div>
                    )}
                </div>
            </nav>

            {/* Login Popup */}
            {showLoginPopup && (
                <div className="popup">
                    <div className="popup-inner">
                        <h2 className="popup-title">Admin Login</h2>
                        <input
                            type="text"
                            name="username"
                            placeholder="Username"
                            className="popup-input"
                            value={loginCredentials.username}
                            onChange={handleInputChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="popup-input"
                            value={loginCredentials.password}
                            onChange={handleInputChange}
                        />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        {networkError && <p className="error-message">{networkError}</p>}
                        <div className="popup-buttons">
                            <button onClick={submitLogin} className="btn btn-primary" disabled={loading}>
                                {loading ? 'Logging in...' : 'Login'}
                            </button>
                            <button onClick={() => setShowLoginPopup(false)} className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Logout Confirmation Popup */}
            {showLogoutPopup && (
                <div className="popup">
                    <div className="popup-inner">
                        <h2 className="popup-title">Logout Confirmation</h2>
                        <p>Are you sure you want to log out?</p>
                        <div className="popup-buttons">
                            <button onClick={confirmLogout} className="btn btn-primary">Logout</button>
                            <button onClick={cancelLogout} className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
};

Header.propTypes = {
    totalPrice: PropTypes.number.isRequired,
};

export default Header;
