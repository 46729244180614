import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Buy.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEye, faVideo } from '@fortawesome/free-solid-svg-icons';
import CategorySlider from './CategorySlider';
import { useNavigate } from 'react-router-dom';
import { useCart } from './CartContext';
import Dropdown from './Dropdown'; 
// Use the environment variable for Azure Blob Storage URL, or fallback to hardcoded URL
const blobUrl = process.env.REACT_APP_BLOB_URL || "https://shivpratishthan.blob.core.windows.net/shivpratishthan619/images/";


const Buy = () => {
    const [categories, setCategories] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { addToCart, cart, removeFromCart } = useCart();
    const [showImageModal, setShowImageModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://shivprathishthanfatakamart.in/products-by-category');
                setCategories(response.data);
                setLoading(false);
            } catch (err) {
                setError('Error fetching categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleQuantityChange = (productId, operation) => {
        const currentQuantity = cart.find(item => item.id === productId)?.quantity || 0;
        if (operation === 'increment') {
            const product = categories[Object.keys(categories).find(category => 
                categories[category].products.find(p => p.id === productId))].products.find(p => p.id === productId);
            addToCart(product);
        } else if (operation === 'decrement' && currentQuantity > 0) {
            removeFromCart(productId);
        }
    };

    const handleAddToCart = (product) => {
        addToCart(product);
    };

    const handleViewImage = (imageUrl) => {
        setModalImage(imageUrl);
        setShowImageModal(true);
    };

    const handleCloseImageModal = () => {
        setShowImageModal(false);
        setModalImage('');
    };

    const handlePlayVideo = (videoUrl) => {
        setVideoUrl(videoUrl);
        setShowVideoModal(true);
    };

    const handleCloseVideoModal = () => {
        setShowVideoModal(false);
        setVideoUrl('');
    };

    const handleViewProductDetails = (productId) => {
        navigate(`/product/${productId}`);
    };

    const handleProceedToCheckout = () => {
        navigate('/checkout');
    };

    const handleCategoryClick = (categoryId) => {
        const element = document.getElementById(`category-${categoryId}`);
        if (element) {
            const topOffset = element.getBoundingClientRect().top + window.scrollY - 100; // Adjust this offset as needed
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="buy-container">
            <div className="main-content">
                <CategorySlider />

                {/* Dropdown Button Displayed Here */}
                <div className="dropdown-container">
                    <button
                        className="btn btn-primary dropdown-toggle"
                        type="button"
                        onClick={toggleDropdown}
                    >
                        Select Category
                    </button>
                    {isDropdownOpen && (
                        <ul className="dropdown-menu show">
                            {Object.keys(categories).map((categoryId) => (
                                <li key={categoryId} onClick={() => handleCategoryClick(categoryId)}>
                                    <button className="dropdown-item">
                                        {categories[categoryId].name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="products-by-category">
                    {Object.keys(categories).map((categoryId) => {
                        const category = categories[categoryId];
                        return (
                            <div key={categoryId} className="category-section" id={`category-${categoryId}`}>
                                <h2 className="gallery-heading mt-5">{category.name}</h2>
                                <div className="product-list">
                                    {category.products.map((product) => {
                                        const price = parseFloat(product.price) || 0;
                                        const currentQuantity = cart.find(item => item.id === product.id)?.quantity || 0;

                                        return (
                                            <div className="product-item" key={product.id}>
                                                <div className="product-image-container">
                                                    <img
                                                        src={`${blobUrl}${product.image_url}`} // Construct the full image URL
                                                        alt={product.name}
                                                        className="product-image"
                                                    />
                                                    <div className="hover-icons">
                                                        <FontAwesomeIcon
                                                            icon={faCamera}
                                                            className="hover-icon"
                                                            onClick={() => handleViewImage(`${blobUrl}${product.image_url}`)} // Show image on camera click
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faEye}
                                                            className="hover-icon"
                                                            onClick={() => handleViewProductDetails(product.id)}
                                                        />
                                                        {product.product_video && (
                                                            <FontAwesomeIcon
                                                                icon={faVideo}
                                                                className="hover-icon"
                                                                onClick={() => handlePlayVideo(product.product_video)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name">{product.name}</h3>
                                                    <p className="product-price">
                                                    INR {parseFloat(product.discount_price).toFixed(2)}
                                                    {' '}
                                
                                                        {product.discount_price > 0 && (
                                                            <>
                                                                <span className="original-price">
                                                                INR {price.toFixed(2)}
                                                                </span>{' '}
                                                                
                                                            </>
                                                        )}
                                                    </p>

                                                    {currentQuantity > 0 ? (
                                                        <div className="quantity-selection">
                                                            <button
                                                                className="quantity-button"
                                                                onClick={() => handleQuantityChange(product.id, 'decrement')}
                                                            >
                                                                -
                                                            </button>
                                                            <input
                                                                type="number"
                                                                value={currentQuantity}
                                                                readOnly
                                                                className="quantity-input"
                                                            />
                                                            <button
                                                                className="quantity-button"
                                                                onClick={() => handleQuantityChange(product.id, 'increment')}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className="add-to-cart"
                                                            onClick={() => handleAddToCart(product)}
                                                        >
                                                            Add To Cart
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* Image Modal */}
                {showImageModal && (
    <div className="modal fade show d-block" tabIndex="-1" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                <button type="button" className="btn btn-secondary" onClick={handleCloseImageModal}>
                        Close
                    </button>
                    <h5 className="modal-title">Image Preview</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={handleCloseImageModal}
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body">
                    <img
                        src={modalImage}
                        alt="Full Size"
                        className="img-fluid"
                        style={{ maxWidth: '100%', maxHeight: '80vh' }}
                    />
                </div>
                <div className="modal-footer">
                   
                </div>
            </div>
        </div>
    </div>
)}
                {/* Video Modal */}
                {showVideoModal && (
                    <div className="modal fade show d-block" tabIndex="-1" style={{ display: 'block' }}>
                        
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleCloseVideoModal}>
                                        Close
                                    </button>
                                </div>
                                    <h5 className="modal-title">Video Preview</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleCloseVideoModal}
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body">
                                    <iframe
                                        src={videoUrl.replace('watch?v=', 'embed/') + '?autoplay=1'} // Replace for embed URL and autoplay
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="Product Video"
                                        className="w-100"
                                        style={{ height: '400px' }}
                                    />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Buy;
