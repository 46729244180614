import React from 'react';
import { Link } from 'react-router-dom';
import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <h1 className='gallery-heading'>Admin Panel</h1>
      <div className="admin-options">
        <div className="admin-row">
          <Link to="/add-category" className="admin-option add-category">
            Add Category
          </Link>
          <Link to="/edit-category" className="admin-option edit-category">
            Edit Categories
          </Link>
        </div>

        <div className="admin-row">
          <Link to="/add-product" className="admin-option add-product1">
            Add Product
          </Link>
          <Link to="/edit-products" className="admin-option edit-product">
            Edit Products
          </Link>
        </div>

       


          

        
      </div>
    </div>
  );
};

export default AdminDashboard;
