import React, { createContext, useState, useContext } from 'react';

// Create a Cart Context
const CartContext = createContext();

// Cart Provider Component
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === product.id);
      let updatedCart;
      let newTotal = total; // Initialize newTotal

      // Use discount_price instead of price for calculations
      const priceToAdd = product.discount_price || product.price; // Use discount_price if available, otherwise use price

      if (existingItem) {
        // If the item already exists, increment the quantity
        updatedCart = prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        newTotal += priceToAdd; // Update total with new price
      } else {
        // If it's a new product, add it to the cart
        updatedCart = [...prevCart, { ...product, quantity: 1 }];
        newTotal += priceToAdd; // Initial price for new product
      }

      setTotal(newTotal); // Update total once
      return updatedCart;
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const itemToRemove = prevCart.find((item) => item.id === productId);
      let updatedCart;
      let newTotal = total; // Initialize newTotal

      if (itemToRemove) {
        if (itemToRemove.quantity > 1) {
          // Decrease quantity
          updatedCart = prevCart.map((item) =>
            item.id === productId
              ? { ...item, quantity: item.quantity - 1 }
              : item
          );
          newTotal -= itemToRemove.discount_price || itemToRemove.price; // Subtract price of one unit (use discount_price if available)
        } else {
          // Remove from cart if quantity is 1
          updatedCart = prevCart.filter((item) => item.id !== productId);
          newTotal -= itemToRemove.discount_price || itemToRemove.price; // Deduct price of the single item
        }
      } else {
        updatedCart = prevCart; // If not found, return previous cart
      }

      setTotal(Math.max(newTotal, 0)); // Ensure total doesn't go below zero
      return updatedCart;
    });
  };

  return (
    <CartContext.Provider value={{ cart, total, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => {
  return useContext(CartContext);
};
